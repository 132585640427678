<template>
  <v-container fluid class="px-10">
    <v-row>
      <v-col xs="12" lg="4">
        <h1 class="secondary--text">Coletores</h1>
      </v-col>
    </v-row>
    <v-row class="align-center justify-space-between">
      <v-col cols="12" xs="6" sm="6" md="1" lg="1">
        <h3 class="">Tipo:</h3>
      </v-col>
      <v-col cols="12" xs="6" sm="6" md="2" lg="2">
        <v-btn
          class="white--text"
          :outlined="search.type === 'carrier'"
          block
          dense
          color="textPrimary"
          hide-details
          @click="changeToClient()"
        >
          Cliente
        </v-btn>
      </v-col>
      <v-col cols="12" xs="6" sm="6" md="2" lg="2">
        <v-btn
          class="white--text"
          :outlined="search.type === 'client'"
          block
          dense
          hide-details
          color="textPrimary"
          @click="changeToCarrier()"
        >
          Operadora
        </v-btn>
      </v-col>
      <v-col v-if="search.type === 'client'" cols="12" xs="6" sm="6" md="3" lg="3">
        <v-autocomplete
          v-model="search.financialGroupId"
          :items="financialGroups"
          item-text="name"
          dense
          item-value="id"
          hide-details
          placeholder="Selecione o Grupo Financeiro"
          outlined
          :loading="loadingFinancialGroups"
          :disabled="isDisable"
          color="textPrimary"
          @input="loadCollectorsByFinancialGroupId('filter')"
        />
      </v-col>
      <v-col v-if="search.type === 'carrier'" cols="12" xs="6" sm="6" md="3" lg="3">
        <v-autocomplete
          v-model="search.carrierId"
          :items="insuranceCarriers"
          :disabled="loadingInsuranceCarriers"
          item-text="xipp_commercial_name"
          dense
          item-value="id"
          hide-details
          placeholder="Selecione a Operadora"
          outlined
          :loading="loadingInsuranceCarriers"
          color="textPrimary"
          @input="loadCollectorsByCarrierId()"
        />
      </v-col>
      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
        <v-btn
          v-if="hasAuthorityConfigEditCopyCollector"
          x-large
          class="float-right"
          color="primary"
          @click="createCollector()"
          min-height="52"
        >
          <v-icon style="font-size: 17px" class="mr-2">fa-cog</v-icon>Configurar coletor
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="search.type === 'client'">
      <v-col lg="12">
        <v-data-table
          color="primary"
          :headers="headersClient"
          :items="collectors"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :loading="loading"
          :loading-text="message"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`header.actions`]="{ header }">
            <span>{{ hasAuthorityConfigEditCopyCollector || hasAuthorityDeleteCollector ? header.text : null }}</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.configs[0].name ? item.configs[0].name : '-' }}
          </template>
          <template v-slot:[`item.financialGroup`]="{ item }">
            {{ item.financialGroup ? item.financialGroup.name : '-' }}
          </template>
          <template v-slot:[`item.contract`]="{ item }">
            {{ formatContract(item) }}
          </template>
          <template v-slot:[`item.subContract`]="{ item }">
            {{ formatSubContract(item) }}
          </template>
          <template v-slot:[`item.insuranceCarrier`]="{ item }">
            {{ item.insuranceCarrier ? item.insuranceCarrier.name : "-" }}
          </template>
          <template v-slot:[`item.configs`]="{ item }">
            <div class="d-flex">
              <div v-for="collector in item.configs" :key="collector.id">
                <v-chip class="ma-2" small>
                  <span class="mr-2">{{ collector.type === 'PAYROLL' ? 'FOLHA' : collector.type }}</span>
                </v-chip>
              </div>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <div class="menu">
                <v-list dense>
                  <v-list-item-group color="primary">
                    <v-list-item v-if="hasAuthorityConfigEditCopyCollector" @click="editCollector(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="hasAuthorityConfigEditCopyCollector" @click="onClickCopyCollector(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-content-copy</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Copiar configuração</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="hasAuthorityDeleteCollector" @click="onClickDelete(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-menu>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination v-model="page" :length="totalPages" @input="loadCollectorsByFinancialGroupId()"></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="search.type === 'carrier'">
      <v-col xs="12" lg="12">
        <v-data-table
          :headers="headersCarrier"
          :items="collectors"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :loading="loading"
          :loading-text="message"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`header.actions`]="{ header }">
            <span>{{ hasAuthorityConfigEditCopyCollector || hasAuthorityDeleteCollector ? header.text : null }}</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.configs[0].name ? item.configs[0].name : '-' }}
          </template>
          <template v-slot:[`item.insuranceCarrier`]="{ item }">
            {{ item.insuranceCarrier ? item.insuranceCarrier.name : "-" }}
          </template>
          <template v-slot:[`item.configs`]="{ item }">
            <div class="d-flex">
              <div v-for="collector in item.configs" :key="collector.id">
                <v-chip class="ma-2" small>
                  <span class="mr-2">{{ collector.type }}</span>
                </v-chip>
              </div>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <div class="menu">
                <v-list dense>
                  <v-list-item-group color="primary">
                    <v-list-item v-if="hasAuthorityConfigEditCopyCollector" @click="editCollector(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="hasAuthorityConfigEditCopyCollector" @click="onClickCopyCollector(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-content-copy</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Copiar configuração</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="hasAuthorityDeleteCollector" @click="onClickDelete(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-menu>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination v-model="page" :length="totalPages"></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="copyCollectorDialog.show" persistent max-width="1080">
      <v-card class="pa-5">
        <v-card-title class="headline pl-3 ml-3">
          <v-row>
            <v-col cols="12" align="end">
              <v-icon
                large
                color="textPrimary"
                @click="copyCollectorDialog.show = false"
                >fas fa-times</v-icon
              >
            </v-col>
            <v-col
              class="text-h4 mb-10"
              cols="12"
              style="color: #3b495b; font-weight: bold"
              >Cópia de Configuração de Coletor</v-col
            >
            <v-col
              v-if="search.type === 'client'"
              class="text-h5"
              cols="12"
              style="color: #3b495b"
            >
              <v-row>
                <v-col cols="12" md="2" lg="2">Cópia de:</v-col>
                <v-col cols="12" md="4" lg="4">Grupo Financeiro</v-col>
                <v-col cols="12" md="3" lg="3">Contrato</v-col>
                <v-col cols="12" md="3" lg="3">Sub-Contrato</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2" lg="2"></v-col>
                <v-col cols="12" md="4" lg="4"><b>{{ copyCollectorDialog.copiedCollector.financialGroup ? copyCollectorDialog.copiedCollector.financialGroup.name : "-" }}</b></v-col>
                <v-col cols="12" md="3" lg="3"><b>{{ copyCollectorDialog.copiedCollector.contract ? copyCollectorDialog.copiedCollector.contract.policy : "-" }}</b></v-col>
                <v-col cols="12" md="3" lg="3"><b>{{ copyCollectorDialog.copiedCollector.subContract ? copyCollectorDialog.copiedCollector.subContract.subContractNumber : "-" }}</b></v-col>
              </v-row>

              <v-row class="mt-10" >
                <v-col cols="4">
                  <v-col>
                    <label class="label">Nome do Coletor</label>
                  </v-col>
                  <v-text-field
                    v-model.trim="copyCollectorDialog.configName"
                    placeholder="Informe o Nome do Coletor"
                    maxlength="70"
                    class="mt-3"
                    color="textPrimary"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-col>
                    <label class="label">Grupo Financeiro</label>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="copyCollectorDialog.financialGroupId"
                      :items="financialGroups"
                      item-text="name"
                      item-value="id"
                      :loading="loadingFinancialGroup"
                      placeholder="Selecione um Grupo Financeiro"
                      outlined
                      color="textPrimary"
                      @input="loadContractsByFinancialGroupId()"
                    />
                  </v-col>
                </v-col>
                <v-col cols="4">
                  <v-col>
                    <label class="label">Contrato</label>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="copyCollectorDialog.contractId"
                      :items="contracts"
                      item-text="text"
                      item-value="id"
                      :loading="loadingContract"
                      placeholder="Selecione um Contrato"
                      :disabled="!copyCollectorDialog.financialGroupId"
                      outlined
                      color="textPrimary"
                      @input="loadSubContractsByContractId()"
                    />
                  </v-col>
                </v-col>
                <v-col cols="4">
                  <v-col>
                    <label class="label">Sub-Contrato</label>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="copyCollectorDialog.subContractId"
                      :items="subContracts"
                      item-text="text"
                      item-value="id"
                      :loading="loadingSubContract"
                      placeholder="Selecione um Subcontrato"
                      :disabled="!copyCollectorDialog.contractId"
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="search.type === 'carrier'"
              class="text-h5"
              cols="12"
              style="color: #3b495b"
            >
              <v-row>
                <v-col cols="12" md="2" lg="2">Cópia de:</v-col>
                <v-col cols="12" md="10" lg="10">Operadora</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2" lg="2"></v-col>
                <v-col cols="12" md="10" lg="10"><b>{{ copyCollectorDialog.copiedCollector.insuranceCarrier ? copyCollectorDialog.copiedCollector.insuranceCarrier.name : "-" }}</b></v-col>
              </v-row>
              <v-row class="mt-10">
                <v-col cols="4">
                  <v-col>
                    <label class="label">Operadora</label>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="copyCollectorDialog.insuranceCarrierId"
                      :items="insuranceCarriers"
                      item-text="xipp_commercial_name"
                      item-value="id"
                      :loading="loadingInsuranceCarrier"
                      placeholder="Selecione uma Operadora"
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions class="mt-10">
          <v-row class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-3 pa-7 btn-dialog"
              color="#3B495B"
              outlined
              width="200px"
              @click="copyCollectorDialog.show = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="mr-6 pa-7 btn-dialog"
              color="#3B495B"
              width="200px"
              style="color: white"
              @click="duplicateCollector()"
            >
              OK
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Dialog
      :show="confirmDeleteDialog.show"
      @close="confirmDeleteDialog.show = false"
      @confirm="deleteCollector(confirmDeleteDialog.id)"
    >
      <template slot="header"> Excluir Coletores Configurados </template>
      <template slot="helper">
        Tem certeza que deseja excluir todas as configurações de coletores
        abaixo?
      </template>
    </Dialog>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>

   <v-row>
      <v-col cols="12" align="end">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="onClickCancel()"
            >
              Voltar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>
   </v-row>
  </v-container>
</template>
<script>
import Rules from '@/shared/validators/formRules';
import Dialog from '@/components/Dialog/Dialog.vue';
import CollectorService from '@/services-http/sdi/CollectorService';
import ContractService from '@/services-http/contract/ContractService';
import UserUtils from '@/shared/utils/user-utils';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import { mapMutations } from 'vuex';

export default {
  name: 'Collectors',
  components: { Dialog },
  data() {
    return {
      hasAuthorityConfigEditCopyCollector: false,
      hasAuthorityDeleteCollector: false,

      headersClient: [
        {
          text: 'Nome',
          value: 'name',
          align: 'start',
        },
        {
          text: 'Grupo Financeiro',
          value: 'financialGroup',
          align: 'start',
        },
        {
          text: 'Contrato',
          align: 'start',
          value: 'contract',
        },
        {
          text: 'Sub-Contrato',
          value: 'subContract',
        },
        {
          text: 'Operadora',
          value: 'insuranceCarrier',
        },
        {
          text: 'Coletores',
          value: 'configs',
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
        },
      ],
      headersCarrier: [
        {
          text: 'Nome',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Operadora',
          align: 'start',
          value: 'insuranceCarrier',
        },
        {
          text: 'Coletores',
          value: 'configs',
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
        },
      ],
      search: {
        type: 'client',
        financialGroupId: '',
        carrierId: '',
      },
      snackbar: {
        show: false,
        text: '',
        type: '',
      },

      overlay: false,
      page: 1,
      totalPages: 0,
      itemsPerPage: 10,
      confirmDeleteDialog: {
        show: false,
        id: '',
      },
      copyCollectorDialog: {
        show: false,
        id: '',
        contractId: '',
        financialGroupId: '',
        insuranceCarrierId: '',
        subContractId: '',
        copiedCollector: {},
        configName: '',
      },
      loadingFinancialGroups: false,
      loadingInsuranceCarriers: false,
      loading: false,
      message: 'Carregando dados',
      loadingContract: false,
      loadingSubContract: false,
      loadingFinancialGroup: false,
      loadingInsuranceCarrier: false,

      financialGroups: [],
      insuranceCarriers: [],
      collectors: [],
      contracts: [],
      subContracts: [],

      financialGroupId: '',
      isDisable: false,
    };
  },

  async mounted() {
    this.resetCollectorsModule();
    this.getInsuranceCarriers();
    this.getFinancialGroups();
    await this.loadAuthorityCollectors();
    this.loading = false;
    this.clearCollectorParams();
    this.search.type = 'client';
    if (this.search.type === 'client') {
      if (this.$route.query.financialGroupId) {
        this.financialGroupId = Number(this.$route.query.financialGroupId);
        this.search.financialGroupId = this.financialGroupId;
        this.loadCollectorsByFinancialGroupId();
        this.isDisable = true;
      } else {
        this.disable = false;
        this.customizeSnackbarMessage('error', 'Selecione um grupo financeiro');
      }
    }
  },

  mixins: [
    FinancialGroupsMixin,
    InsuranceCarriersMixin,
    GenericFunctionsMixin,
  ],

  methods: {
    ...mapMutations({
      resetCollectorsModule: 'collectors/resetModule',

      setCollector: 'collectors/setCollector',
      setEditCollector: 'collectors/setEditCollector',
      setCollectorParams: 'collectors/setCollectorParams',
      setCollectorsConfigs: 'collectors/setCollectorsConfigs',
    }),
    async loadAuthorityCollectors() {
      this.hasAuthorityConfigEditCopyCollector = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_coletores_configuracao_edicao_copiar' });
      this.hasAuthorityDeleteCollector = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_coletores_excluir' });
    },
    createCollector() {
      // sessionStorage.setItem('collector', this.search.type);
      this.setCollector(this.search.type);
      if (this.search.type === 'client') {
        if (this.search.financialGroupId) {
          this.$router.push({ name: 'CollectorsNew', query: { financialGroupId: this.search.financialGroupId } });
        } else {
          this.$router.push({ name: 'CollectorsNew' });
        }
      } else if (this.search.type === 'carrier') {
        if (this.search.carrierId) {
          this.$router.push({ name: 'CollectorsNew', query: { insuranceCarrierId: this.search.carrierId } });
        } else {
          this.$router.push({ name: 'CollectorsNew' });
        }
      }
    },
    editCollector(item) {
      // sessionStorage.setItem('editCollector', JSON.stringify(item));
      this.setEditCollector(item);
      this.$router.push({
        name: 'CollectorIntegration',
        query: { isEdit: true },
      });
    },
    onClickCopyCollector(item) {
      if (this.search.type === 'client') {
        this.copyCollectorDialog = {
          show: true,
          id: item.id,
          contractId: '',
          subContractId: '',
          financialGroupId: '',
          copiedCollector: item,
          configName: '',
        };
      }
      if (this.search.type === 'carrier') {
        this.copyCollectorDialog = {
          show: true,
          id: item.id,
          insuranceCarrierId: '',
          copiedCollector: item,
          configName: '',
        };
      }
    },
    async duplicateCollector() {
      this.copyCollectorDialog.show = false;
      this.overlay = true;
      await this.collectorService.CopyCollector(this.copyCollectorDialog).then(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('success', 'Coletor copiado com sucesso');
        this.refreshSearch();
      }).catch(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar copiar o coletor');
      });
    },
    onClickDelete(item) {
      this.confirmDeleteDialog.show = true;
      this.confirmDeleteDialog.id = item.id;
    },
    async deleteCollector() {
      this.confirmDeleteDialog.show = false;
      this.overlay = true;
      await this.collectorService.DeleteById(this.confirmDeleteDialog.id).then(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('success', 'Coletor deletado com sucesso');
        this.refreshSearch();
      }).catch(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao excluir o coletor');
      });
      return '';
    },
    changeToClient() {
      this.search.type = 'client';
      if (this.isDisable) {
        this.search.financialGroupId = this.financialGroupId;
        this.loadCollectorsByFinancialGroupId();
      } else {
        this.search.financialGroupId = '';
      }
      this.search.carrierId = '';
      this.collectors = [];
    },
    changeToCarrier() {
      this.search.type = 'carrier';
      this.search.financialGroupId = '';
      this.search.carrierId = '';
      this.collectors = [];
    },
    refreshSearch() {
      if (this.search.type === 'client') {
        this.loadCollectorsByFinancialGroupId();
      }
      if (this.search.type === 'carrier') {
        this.loadCollectorsByCarrierId();
      }
    },
    formatContract(item) {
      let text = '';
      if (item.contract) {
        text = `${item.contract.benefit ? item.contract.benefit : '-'} - ${item.contract.carrierName ? item.contract.carrierName : '-'} - ${item.contract.legalName ? item.contract.legalName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
    formatSubContract(item) {
      let text = '';
      if (item.subContract) {
        text += item.subContract.legalName ? item.subContract.legalName : '';
      } else {
        text = '-';
      }
      return text;
    },
    async loadContractsByFinancialGroupId() {
      if (this.copyCollectorDialog.financialGroupId) {
        this.loadingContract = true;
        this.contracts = [];
        this.subContracts = [];
        const query = `financialGroupIds=${this.copyCollectorDialog.financialGroupId}`;
        this.contractService.FindAllByFilters(query).then((response) => {
          if (response && response.data && response.data.length > 0) {
            const contracts = response.data.filter((c) => c.subcontract === false);
            this.contructorContractDescription(contracts);
          }
          this.loadingContract = false;
        }).catch((error) => {
          this.loadingContract = false;
        });
      }
    },
    async loadSubContractsByContractId() {
      if (this.copyCollectorDialog.contractId) {
        this.loadingSubContract = true;
        this.subContracts = [];
        this.contractService.FindByParentId(this.copyCollectorDialog.contractId).then((response) => {
          if (response && response.data && response.data.length > 0) {
            const subContracts = response.data.filter((c) => c.subcontract === true);
            this.contructorSubContractDescription(subContracts);
          }
          this.loadingSubContract = false;
        }).catch((error) => {
          this.loadingSubContract = false;
        });
      }
    },
    contructorContractDescription(contracts) {
      if (contracts) {
        this.contracts = contracts.map((item) => ({
          ...item,
          text: (`${(item.benefit_id && item.benefit_id.name ? item.benefit_id.name : item.benefit_id ? item.benefit_id : '-')} - ${(item.carrier_id && item.carrier_id.legal_name ? item.carrier_id.legal_name : '-')} - ${item.subcontract_number ? item.subcontract_number : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
        }));
      }
    },
    contructorSubContractDescription(subContracts) {
      if (subContracts) {
        this.subContracts = subContracts.map((item) => ({
          ...item,
          text: (`${(item.benefit_id && item.benefit_id.name ? item.benefit_id.name : item.benefit_id ? item.benefit_id : '-')} - ${(item.carrier_id && item.carrier_id.legal_name ? item.carrier_id.legal_name : '-')} - ${item.subcontract_number ? item.subcontract_number : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
        }));
      }
    },
    async loadCollectorsByFinancialGroupId(type) {
      if (type === 'filter') this.page = 1;
      this.loading = true;
      const pagination = this.page - 1;
      const size = 10;
      const filter = `?financialGroupId=${this.search.financialGroupId}&page=${pagination}&size=${size}`;
      await this.collectorService.FindAll(filter).then((response) => {
        this.collectors = response.data;
        this.totalPages = response.data.totalPages;
        this.loading = false;
      });
    },
    async loadCollectorsByCarrierId(type) {
      if (type === 'filter') this.page = 1;
      this.loading = true;
      const pagination = this.page - 1;
      const size = 10;
      const filter = `?insuranceCarrierId=${this.search.carrierId}&page=${pagination}&size=${size}`;
      await this.collectorService.FindAll(filter).then((response) => {
        this.collectors = response.data;
        this.totalPages = response.data.totalPages;
        this.loading = false;
      });
    },
    clearCollectorParams() {
      const collectorType = 'collectorParams';
      const collectorParams = sessionStorage.getItem(collectorType);
      this.clearSessionStorage();
      // sessionStorage.setItem(collectorType, collectorParams);
      sessionStorage.removeItem('selectedDate');
      if (collectorParams) this.setCollectorParams(collectorParams)
    },
    onClickCancel() {
      this.resetCollectorsModule();
      this.$router.push({ name: 'HomeAllMenus' });
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },
  async created() {
    this.rule = new Rules();
    this.userUtils = new UserUtils();
    this.collectorService = new CollectorService();
    this.contractService = new ContractService();
  },
};
</script>
