import CarrierService from '@/services-http/contract/CarrierService';

export default {
  methods: {
    async getInsuranceCarriers() {
      const carrierService = new CarrierService();
      this.loadingInsuranceCarriers = true;
      await carrierService.FindAll().then((response) => {
        if (response && response.data) {
          this.insuranceCarriers = response.data;
          this.insuranceCarriers.sort((a, b) => (a.xipp_commercial_name > b.xipp_commercial_name ? 1 : -1));
          this.loadingInsuranceCarriers = false;
        }
      }).catch(() => {
        this.loadingInsuranceCarriers = false;
      });
    },
    getInsuranceCarrierNameById(id) {
      const carrierFound = this.insuranceCarriers.find((carrier) => carrier.id === id);
      if (carrierFound) {
        return carrierFound.xipp_commercial_name;
      }
      return null;
    },
  },
};
