import FinancialGroupService from '@/services-http/contract/FinancialGroupService';

export default {
  methods: {
    async getFinancialGroups() {
      const financialGroupService = new FinancialGroupService();
      this.loadingFinancialGroups = true;
      const queryString = '?orderByName=true';
      await financialGroupService.FindAllWithFilters(queryString).then(async (response) => {
        if (response && response.data) {
          this.financialGroups = response.data;
        }
      }).catch(() => {
        this.loadingFinancialGroups = false;
      }).finally(() => {
        this.loadingFinancialGroups = false;
      });
    },
    getFinancialGroupNameById(financialGroupSelected) {
      const financialGroupFound = this.financialGroups.find((financialGroup) => financialGroup.id === financialGroupSelected);
      return financialGroupFound ? financialGroupFound.name : null;
    },
  },
};
